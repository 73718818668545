import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputNumberFormat from 'react-number-format';
import ReactSelect from 'react-select';

import { InfoCircleFill as InfoCircleFillIcon } from '@styled-icons/bootstrap/InfoCircleFill';
import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Button,
  Icon,
  Grid,
  FormControl,
  FormLabel,
  Flex,
  Input,
  Checkbox,
  Text,
  Tooltip,
  FormErrorMessage,
} from '@chakra-ui/react';

import { UnitMeasure } from 'types/base';

// import { UnitMeasure } from 'types/base';

export interface FormData {
  code: string;
  type: 'separate';
  description: string;
  unit_measure_id: number;
  observation?: string;
  immobilized: boolean;
  unit_price: number;
  labor: number;
  material: number;
  labor_percent: number;
  material_percent: number;
  equipment: number;
  refund: number;
  third_party_services: number;
  other: number;
  quantity: number;
  total_value: number;
}

interface FormProps {
  onSubmit(data: FormData): void;
  isSaving?: boolean;
}

const SearchFilter: React.FC<FormProps> = ({ onSubmit, isSaving = false }) => {
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const {
    handleSubmit,
    setValue,
    control,
    register,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      labor_percent: 50,
      material_percent: 50,
      refund: 0,
    },
  });

  const [unitMeasures, setUnitMeasures] = useState<UnitMeasure[]>([]);
  const [selectedUnitMeasure, setSelectedUnitMeasure] = useState<number>();

  const prepareSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data,
      });
    },
    [onSubmit],
  );

  const getUnitMeasures = useCallback(async () => {
    setIsLoadingFilters(true);

    try {
      const response = await api.get('/unit/measure/list');
      const unitMeasuresLoaded = response.data;
      setUnitMeasures(unitMeasuresLoaded.data);
    } catch (err) {
      toast({
        description: 'Houve um erro ao carregar as unidades de medida!',
        status: 'error',
      });
    } finally {
      setIsLoadingFilters(false);
    }
  }, []);

  const unitMeasuresToSelect = useMemo(() => {
    return unitMeasures.map((unitMeasure) => ({
      value: String(unitMeasure.id),
      label: unitMeasure.description,
    }));
  }, [unitMeasures]);

  useEffect(() => {
    getUnitMeasures();
  }, [getUnitMeasures]);

  const [
    unitPrice,
    quantity,
    immobilized,
    laborPercent,
    materialPercent,
    refund,
  ] = watch([
    'unit_price',
    'quantity',
    'immobilized',
    'labor_percent',
    'material_percent',
    'refund',
  ]);

  useEffect(() => {
    const total = unitPrice * Number(String(quantity).replace(',', '.'));
    setValue('total_value', total);
  }, [setValue, unitPrice, quantity]);

  useEffect(() => {
    if (immobilized) {
      setValue('refund', 100);
      setValue('material_percent', 0);
      setValue('labor_percent', 0);
    } else {
      setValue('refund', 0);
      setValue('material_percent', 50);
      setValue('labor_percent', 50);
    }
  }, [setValue, immobilized]);

  useEffect(() => {
    setValue('labor', unitPrice * (laborPercent / 100));
  }, [setValue, laborPercent, unitPrice]);

  useEffect(() => {
    setValue('material', unitPrice * (materialPercent / 100));
  }, [setValue, materialPercent, unitPrice]);

  return (
    <Flex
      w="100%"
      as="form"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        '> *': {
          marginY: 1,
        },
      }}
      onSubmit={handleSubmit(prepareSubmit)}
    >
      <FormControl isInvalid={!!errors.description}>
        <FormLabel>Descrição</FormLabel>

        <Input {...register('description', { required: true })} />

        <FormErrorMessage>Descrição é obrigatória</FormErrorMessage>
      </FormControl>

      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={4}
      >
        <FormControl isInvalid={!!errors.unit_measure_id}>
          <FormLabel>Unidade de medida</FormLabel>

          <Controller
            render={(field) => (
              <ReactSelect
                {...field}
                placeholder="Selecione"
                maxMenuHeight={200}
                options={unitMeasuresToSelect}
                value={unitMeasuresToSelect.filter(
                  (s) => Number(s.value) === selectedUnitMeasure,
                )}
                isLoading={isLoadingFilters}
                isDisabled={unitMeasuresToSelect.length === 0}
                isClearable
                onChange={(selected) => {
                  const value = Number(selected?.value);

                  setSelectedUnitMeasure(value);
                  setValue('unit_measure_id', value);
                }}
              />
            )}
            name="unit_measure_id"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>Unidade de medida é obrigatória</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.unit_price}>
          <FormLabel>Preço unitário (R$)</FormLabel>

          <Controller
            render={() => (
              <InputNumberFormat
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                onValueChange={(v) => {
                  setValue('unit_price', v.floatValue || 0);
                }}
                customInput={Input}
              />
            )}
            name="unit_price"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>Preço unitário é obrigatório</FormErrorMessage>
        </FormControl>
      </Grid>

      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={4}
      >
        <FormControl isInvalid={!!errors.quantity}>
          <FormLabel>Quantidade</FormLabel>

          <Controller
            render={({ field }) => (
              <InputNumberFormat
                {...field}
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                decimalScale={4}
                onValueChange={(v) => {
                  setValue('quantity', v.floatValue || 0);
                }}
                customInput={Input}
              />
            )}
            name="quantity"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>Quantidade é obrigatória</FormErrorMessage>
        </FormControl>

        <FormControl isReadOnly>
          <FormLabel>Mão de obra (%)</FormLabel>

          <Controller
            render={() => (
              <InputNumberFormat
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                suffix="%"
                decimalScale={2}
                fixedDecimalScale
                readOnly
                value={laborPercent}
                onValueChange={(v) => {
                  setValue('labor_percent', v.floatValue || 0);
                }}
                customInput={Input}
              />
            )}
            name="labor_percent"
            control={control}
          />
        </FormControl>

        <FormControl isReadOnly>
          <FormLabel>Material (%)</FormLabel>

          <Controller
            render={() => (
              <InputNumberFormat
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                suffix="%"
                decimalScale={2}
                fixedDecimalScale
                readOnly
                value={materialPercent}
                onValueChange={(v) => {
                  setValue('material_percent', v.floatValue || 0);
                }}
                customInput={Input}
              />
            )}
            name="material_percent"
            control={control}
          />
        </FormControl>
      </Grid>

      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(250px, 1fr))"
        columnGap={2}
        rowGap={4}
      >
        <FormControl isReadOnly>
          <FormLabel>Reembolso (%)</FormLabel>

          <Controller
            render={() => (
              <InputNumberFormat
                type="tel"
                className="text-right"
                displayType="input"
                allowNegative={false}
                suffix="%"
                fixedDecimalScale
                readOnly
                value={refund}
                onValueChange={(v) => {
                  setValue('refund', v.floatValue || 0);
                }}
                customInput={Input}
              />
            )}
            name="refund"
            control={control}
          />
        </FormControl>

        <FormControl isReadOnly>
          <FormLabel>Valor M.O (R$)</FormLabel>

          <Controller
            render={({ field }) => (
              <InputNumberFormat
                {...field}
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                customInput={Input}
              />
            )}
            name="labor"
            control={control}
          />
        </FormControl>

        <FormControl isReadOnly>
          <FormLabel>Valor Material (R$)</FormLabel>

          <Controller
            render={({ field }) => (
              <InputNumberFormat
                {...field}
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                customInput={Input}
              />
            )}
            name="material"
            control={control}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Total Geral (R$)</FormLabel>

          <Controller
            render={({ field }) => (
              <InputNumberFormat
                {...field}
                type="tel"
                className="text-right"
                readOnly
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                customInput={Input}
              />
            )}
            name="total_value"
            control={control}
          />
        </FormControl>
      </Grid>

      <Checkbox alignSelf="flex-start" {...register('immobilized')}>
        <Text fontWeight="500">
          Imobilizado{' '}
          <Tooltip hasArrow label="Marque essa opção para itens imobilizados.">
            <Icon as={InfoCircleFillIcon} className="icon" />
          </Tooltip>
        </Text>
      </Checkbox>

      <Button
        w="100%"
        type="submit"
        colorScheme="blue"
        isLoading={isSaving}
        leftIcon={<Icon as={CheckIcon} />}
        mt="4"
      >
        Salvar
      </Button>
    </Flex>
  );
};

export default SearchFilter;
