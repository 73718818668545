import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  FormErrorMessage,
  Flex,
  Text,
  Grid,
  Textarea,
  HStack,
  Box,
  keyframes,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import { CompositionCustom } from 'pages/Component/Search/types';

import useModal from 'hooks/useModal';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

type Action =
  CompositionCustom['budget_composition_price']['flow_status']['actions'][number];

interface ModalManageCompositionFlowProps extends ModalRootProps {
  data: {
    composition: CompositionCustom;
  };
}

const spin = keyframes`
  0% {opacity: 0; scale: 0;}
  50% {opacity: 0.3;  scale: 1;}
  100% {opacity: 0;  scale: 0;}
`;

const ModalManageCompositionFlow: React.FC<ModalManageCompositionFlowProps> = ({
  onConfirm,
  handleClose,
  data,
  ...restProps
}) => {
  const { composition } = data;

  const { openModal } = useModal();

  const [selectedAction, setSelectedAction] = useState<Action>();

  const [observation, setObservation] = useState<string>();

  const [actions, setData] = useState<Action[]>([]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrors | undefined>();

  const spinAnimation = `${spin} infinite 2s ease`;

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get(
        `flow/status/${composition.budget_composition_price.flow_status.id}/action`,
      );

      const actionList = response.data;

      setData(actionList.data);
    } catch (err) {
      toast({
        description: 'Houve um erro ao carregar as ações!',
        status: 'error',
      });
      setData([]);
    } finally {
      setLoading(false);
    }
  }, [composition]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleConfirm = async (): Promise<void> => {
    const formErrors = {} as FormErrors;

    if (!selectedAction) {
      Object.assign(formErrors, { selectedAction: true, type: 'required' });
    }

    if (!observation) {
      Object.assign(formErrors, { observation: true, type: 'required' });
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      await api.post(`/flow/action/${selectedAction?.id}`, {
        object_type: 'budget_composition_price',
        object_id: composition.budget_composition_price.id,
        flow_key: 'use_flow_omitted',
        type: 'manual',
        message: observation,
      });

      toast({
        description: `Status atualizado com sucesso!`,
        status: 'success',
      });

      if (onConfirm) onConfirm();
      handleClose();
    } catch (err) {
      toast({
        description: 'Houve um erro ao performar a ação!',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Atualizar status do item complementar</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Grid
            w="100%"
            templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
            columnGap={2}
            rowGap={4}
            paddingY={4}
            borderTop="1px solid rgba(0,0,0,0.1)"
            borderBottom="1px solid rgba(0,0,0,0.1)"
          >
            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Descrição: </Text>
              <Text noOfLines={1} textOverflow="ellipsis">
                {composition.budget_composition_price.description ||
                  'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Status atual: </Text>
              <Text noOfLines={1} textOverflow="ellipsis">
                {composition.budget_composition_price.flow_status?.name ||
                  'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Total: </Text>
              <Text>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(composition.total)}{' '}
              </Text>
            </Flex>
          </Grid>

          <FormControl marginTop="4" isInvalid={!!errors?.selectedAction}>
            <FormLabel>Ação</FormLabel>
            <Menu size="lg" isLazy>
              <MenuButton
                w="100%"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size="md"
                textAlign="left"
                disabled={loading}
                borderColor={errors?.selectedAction ? 'red.500' : 'unset'}
                color={errors?.selectedAction ? 'white.200' : 'unset'}
                bgColor={errors?.selectedAction ? 'red.200' : 'gray.200'}
                _hover={{
                  bgColor: errors?.selectedAction ? 'red.200' : 'gray.200',
                }}
                _active={{
                  bgColor: errors?.selectedAction ? 'red.200' : 'gray.200',
                }}
              >
                {selectedAction ? selectedAction.name : 'Selecione'}
              </MenuButton>

              <MenuList>
                {actions.map((item, i, array) => (
                  <Fragment key={item.id}>
                    <MenuItem
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      pr={{ base: 2, md: 96 }}
                      onClick={() => {
                        setSelectedAction(item);
                      }}
                    >
                      <Flex direction="row" alignItems="center">
                        <Box
                          width={3}
                          height={3}
                          borderRadius="50%"
                          bg={item.color}
                          position="relative"
                          boxShadow="base"
                          flexShrink={0}
                          _before={{
                            content: '""',
                            position: 'absolute',
                            top: -1,
                            left: -1,
                            right: -1,
                            bottom: -1,
                            bg: item.color,
                            opacity: 0.3,
                            borderRadius: '50%',
                            animation: spinAnimation,
                          }}
                          marginRight={2}
                        />
                        <b>{item.name}</b>
                      </Flex>
                      {item.description}
                    </MenuItem>

                    {i !== array.length - 1 && <MenuDivider />}
                  </Fragment>
                ))}

                {actions.length === 0 && (
                  <MenuItem
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <b>Nenhuma ação disponível</b>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>

            <FormErrorMessage>Ação é obrigatória</FormErrorMessage>
          </FormControl>

          <FormControl marginTop="4" isInvalid={!!errors?.observation}>
            <FormLabel>Justificativa</FormLabel>
            <Textarea
              resize="none"
              onChange={(e) => setObservation(e.target.value)}
            />
            <FormErrorMessage>Justificativa é obrigatória</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            <Button
              isLoading={loading}
              isDisabled={loading}
              onClick={() =>
                openModal('customCompositionHistory', {
                  mode: 'lookup',
                  data: {
                    composition,
                  },
                })
              }
            >
              Ver histórico
            </Button>

            <Button
              isLoading={loading}
              isDisabled={loading}
              colorScheme="green"
              onClick={handleConfirm}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalManageCompositionFlow;
