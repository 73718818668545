import React from 'react';

import { Flex, Text, Box, keyframes } from '@chakra-ui/react';

type Size = 'xs' | 'x-small' | 'sm' | 'md' | 'lg';
type Weight = 'normal' | 'medium' | 'semibold' | 'bold';

type CenteredProps = {
  height: string;
  display: string;
  alignItems: string;
  justifyContent: string;
};

interface StatusCircleProps {
  colorScheme: string;
  colorSchemeType?: 'manual' | 'chakra';
  children: string;
  hasCircle?: boolean;
  centered?: boolean;
  onClick?: () => void;
  title?: string;
  size?: Size;
  weight?: Weight;
}

const spin = keyframes`
  0% {opacity: 0; scale: 0;}
  50% {opacity: 0.3;  scale: 1;}
  100% {opacity: 0;  scale: 0;}
`;

const StatusCircle: React.FC<StatusCircleProps> = ({
  colorScheme,
  colorSchemeType,
  children,
  hasCircle = true,
  centered = false,
  onClick,
  title,
  size = 'x-small',
  weight = 'bold',
}) => {
  const spinAnimation = `${spin} infinite 2s ease`;

  const [color] = colorScheme.split('.');

  const getCenteredProps = (): CenteredProps => {
    return {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
  };

  return (
    <Flex direction="row" alignItems="center">
      {hasCircle && (
        <Box
          width={3}
          height={3}
          borderRadius="50%"
          bg={colorSchemeType ? color : `${color}.500`}
          position="relative"
          boxShadow="base"
          flexShrink={0}
          _before={{
            content: '""',
            position: 'absolute',
            top: -1,
            left: -1,
            right: -1,
            bottom: -1,
            bg: colorSchemeType ? color : `${color}.100`,
            opacity: 0.3,
            borderRadius: '50%',
            animation: spinAnimation,
          }}
          marginRight={2}
        />
      )}
      <Text
        fontSize={size}
        fontWeight={weight}
        color="white"
        bg={colorSchemeType ? color : `${color}.400`}
        padding={1}
        paddingX={2}
        borderRadius="base"
        whiteSpace="nowrap"
        onClick={onClick}
        cursor={onClick ? 'pointer' : 'auto'}
        {...(centered && getCenteredProps())}
        title={title}
      >
        {children}
      </Text>
    </Flex>
  );
};

export default StatusCircle;
