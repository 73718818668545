import React, { useState, useCallback, useEffect, useContext } from 'react';

import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { Close as CloseIcon } from '@styled-icons/evaicons-solid/Close';
import { api } from 'services/api';
import { isAxiosError } from 'services/axios';
import { formatDate } from 'shared/date';
import { getColorByRole, getRoleName } from 'shared/roles';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  SimpleGrid,
  HStack,
  Text,
  Select,
  Flex,
  Input,
  Badge,
  useMediaQuery,
  Icon,
  IconButton,
} from '@chakra-ui/react';

import AppTable from 'components/AppTable';
import { ModalRootProps } from 'components/Modal/Root';
import PaginationWrapper from 'components/Pagination';

import AuthContext from 'contexts/AuthContext';

import useDebouncedState from 'hooks/useDebouncedState';
import useThrottledState from 'hooks/useThrottledState';

import { OrganizationUser } from 'types/access-control';
import { Pagination, ServicePagination } from 'types/pagination';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

interface ModalCategoryUserAddProps extends ModalRootProps {
  data: {
    category_id: number | null;
  };
}

const modalMargin = 120;
const modalMobileFix = 100;

const ModalCategoryUserAdd: React.FC<ModalCategoryUserAddProps> = ({
  onConfirm,
  handleClose,
  data: { category_id },
  ...restProps
}) => {
  const { bradescoId } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [isMobile] = useMediaQuery('(max-width: 728px)');

  const [data, setData] = useState<OrganizationUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<OrganizationUser[]>([]);

  const [keyword, setKeyword] = useDebouncedState<string>(
    (() => {
      return '';
    })(),
  );

  const [pagination, setPagination] = useThrottledState<Pagination>(
    (() => {
      return {
        per_page: 10,
        page: 1,
      };
    })(),
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get(`/organization/user`, {
        params: {
          'filter[keyword]': keyword,
          'filter[organization_id]': bradescoId,
          'filter[no_group]': '.',
          page: pagination.page,
          per_page: pagination.per_page,
        },
      });

      const users = response.data;

      const newPagination = {
        last_page: users.meta.last_page,
      };

      setData(users.data);
      setServicePagination(newPagination);
    } catch (err) {
      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [keyword, pagination, bradescoId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSelectUser = (item: OrganizationUser): void => {
    setSelectedUsers((prepared) => [
      ...prepared,
      {
        ...item,
      },
    ]);
  };

  const handleDeleteUser = (item: OrganizationUser): void => {
    setSelectedUsers((selected) => selected.filter((c) => c.id !== item.id));
  };

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (selectedUsers.length === 0) {
      Object.assign(formErrors, { category: true, type: 'required' });
    }

    if (Object.keys(formErrors).length) {
      toast({
        description: 'Selecione ao menos um usuário.',
        status: 'error',
      });

      return;
    }

    try {
      setSaving(true);

      const userIds = selectedUsers.map((item) => item.user.id);

      const userCategoriesRequests = userIds.map((userId) =>
        api.post(`/user/${userId}/category`, {
          categories: [{ category_id }],
        }),
      );

      const responses = await Promise.allSettled(userCategoriesRequests);

      if (responses.some((res) => res.status === 'rejected')) {
        toast({
          description: 'Houve um erro ao tentar salvar as equipes',
          status: 'error',
        });

        return;
      }

      toast({
        description: 'Equipes vinculadas com sucesso',
        status: 'success',
      });

      if (onConfirm) onConfirm();
      handleClose();
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.data.message) {
          toast({
            description: err.response?.data.message,
            status: 'error',
          });
        } else {
          toast({
            description: 'Houve um erro ao tentar salvar as equipes',
            status: 'error',
          });
        }
      }
    } finally {
      setSaving(false);
    }
  };

  const getUserName = (item: OrganizationUser): string => {
    if (item.user.document_type === 'cnpj') {
      return `${item.user.corporate_name} - ${item.user.fantasy_name}`;
    }

    return item.user.name;
  };

  return (
    <Modal {...restProps} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        sx={{
          width: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxWidth: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          height: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxHeight: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
        }}
      >
        <ModalHeader>Vincular usuários à equipe</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} mt={3}>
            <HStack mb={3}>
              <Text fontSize="smaller">Por página:</Text>
              <Select
                width="auto"
                defaultValue={pagination.per_page}
                onChange={(e) => {
                  setPagination({
                    ...pagination,
                    per_page: Number(e.target.value),
                  });
                }}
              >
                {[5, 10, 20, 50, 100].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </HStack>

            <Flex
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              mb={3}
              gridGap={{ base: 0, md: 3 }}
            >
              <Input
                type="text"
                placeholder="Pesquisar"
                defaultValue={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                mb={{ base: 3, md: 0 }}
              />
            </Flex>
          </SimpleGrid>

          <AppTable
            cols={[
              { field: 'name', description: 'Nome', sortable: false },
              { field: 'email', description: 'E-mail', sortable: false },
              {
                field: 'created_at',
                description: 'Data de cadastro',
                isCentered: true,
                sortable: false,
              },
              { field: 'city', description: 'Cidade / UF', sortable: false },
              { field: 'actions', description: 'Ações', sortable: false },
            ]}
            mapping={(item) => ({
              id: item.id,
              name: (
                <>
                  <Text mb={1}>{getUserName(item) || '-'}</Text>
                  <Text fontWeight="bold" fontSize="smaller">
                    {item.user.document}
                  </Text>
                </>
              ),
              email: (
                <>
                  <Text mb={1}>{item.user.email}</Text>

                  <Badge
                    colorScheme={getColorByRole(item.roles || [])}
                    variant="solid"
                    size="xs"
                  >
                    {getRoleName(item.roles || [])}
                  </Badge>
                </>
              ),
              created_at: formatDate(item.user.created_at),
              city: item.user.city
                ? `${item.user.city.name} / ${item.user.city.state.abbreviation}`
                : '',
              actions: (
                <HStack spacing={2}>
                  <Button
                    colorScheme="green"
                    leftIcon={<Icon as={CheckIcon} />}
                    onClick={() => handleSelectUser(item)}
                    disabled={selectedUsers.some((c) => c.id === item.id)}
                  >
                    {selectedUsers.some((c) => c.id === item.id)
                      ? 'Selecionado'
                      : 'Selecionar'}
                  </Button>

                  {selectedUsers.some((c) => c.id === item.id) && (
                    <IconButton
                      aria-label="Remover usuário"
                      colorScheme="red"
                      icon={<Icon as={CloseIcon} />}
                      onClick={() => handleDeleteUser(item)}
                    />
                  )}
                </HStack>
              ),
              active: selectedUsers.some((c) => c.id === item.id),
            })}
            data={data}
            loading={loading}
            // initialSort={sort}
            onUpdateSort={() => {
              //
            }}
          />

          <PaginationWrapper
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading || saving}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalCategoryUserAdd;
