import React, { useCallback, useState } from 'react';
import InputNumberFormat from 'react-number-format';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Flex,
  Grid,
  FormControl,
  FormLabel,
  Input,
  Heading,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import {
  Composition,
  CompositionCustom,
} from 'pages/Budget/Add/SubPages/Planning/types';

type BudgetComposition = Composition | CompositionCustom;

type CustomPayload = {
  id: number;
  type: string;
  quantity: number;
  description: string;
  unit_measure_id: number;
  observation?: string;
  immobilized: boolean;
  unit_price: number;
  labor: number;
  material: number;
  labor_percent: number;
  material_percent: number;
  equipment: number;
  refund: number;
  third_party_services: number;
  other: number;
};

interface ModalBudgetCompositionQuantityProps extends ModalRootProps {
  data: {
    budgetId: number;
    composition: BudgetComposition;
    isCustomStage: boolean;
    subStageId: number;
  };
}

const ModalBudgetCompositionQuantity: React.FC<ModalBudgetCompositionQuantityProps> =
  ({
    onConfirm,
    handleClose,
    data: { budgetId, composition, isCustomStage },
    ...restProps
  }) => {
    const [quantity, setQuantity] = useState(composition.quantity);
    const [loading, setLoading] = useState(false);

    const isCustomComposition = useCallback(
      (c: BudgetComposition): c is CompositionCustom => {
        return isCustomStage;
      },
      [isCustomStage],
    );

    const getCustomComposition = useCallback(
      (item: CompositionCustom): CustomPayload => {
        return {
          id: item.budget_composition_price.id,
          type: 'separate',
          quantity,
          description: item.budget_composition_price.description,
          unit_measure_id: item.budget_composition_price.unit_measure_id.id,
          observation: item.budget_composition_price.observation,
          immobilized: item.budget_composition_price.immobilized,
          unit_price: item.budget_composition_price.unit_price,
          labor: item.labor,
          material: item.material,
          labor_percent: item.labor_percent,
          material_percent: item.material_percent,
          equipment: item.equipment,
          refund: item.refund,
          third_party_services: item.third_party,
          other: item.other,
        };
      },
      [quantity],
    );

    const handleSubmitComponents = useCallback(async () => {
      setLoading(true);

      try {
        await api.put(`budget/${budgetId}/component/${composition.id}`, {
          type: isCustomComposition(composition)
            ? 'composition_custom'
            : 'composition_original',
          component: isCustomComposition(composition)
            ? { ...getCustomComposition(composition) }
            : {
                quantity,
                composition_price_id: composition.composition_price.id,
              },
        });

        toast({
          description: 'Composição alterada com sucesso!',
          status: 'success',
        });

        if (onConfirm) onConfirm();
        handleClose();
      } catch (err) {
        toast({
          description: 'Houve um erro ao editar a composição.',
          status: 'error',
        });
      } finally {
        setLoading(false);
      }
    }, [
      budgetId,
      composition,
      getCustomComposition,
      isCustomComposition,
      quantity,
      handleClose,
      onConfirm,
    ]);

    return (
      <Modal {...restProps} size="2xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Composição</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Flex
              w="100%"
              as="form"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{
                '> *': {
                  marginY: 1,
                },
              }}
            >
              <Heading
                fontSize="medium"
                fontWeight="600"
                width="100%"
                textAlign="left"
                mt={-2}
                mb={4}
              >
                Composição:{' '}
                {isCustomComposition(composition)
                  ? composition.budget_composition_price.description
                  : composition.composition_price.composition.description}
              </Heading>

              <Grid
                w="100%"
                templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
                columnGap={2}
                rowGap={4}
              >
                <FormControl>
                  <FormLabel>Quantidade</FormLabel>

                  <InputNumberFormat
                    type="tel"
                    className="text-right"
                    displayType="input"
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    decimalScale={4}
                    defaultValue={quantity}
                    onValueChange={(v) => {
                      setQuantity(v.floatValue || 0);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmitComponents();
                      }
                    }}
                    customInput={Input}
                  />
                </FormControl>
              </Grid>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={loading}
              isDisabled={loading}
              colorScheme="green"
              onClick={handleSubmitComponents}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

export default ModalBudgetCompositionQuantity;
