import { env } from 'shared/env';

import { createApiClient } from './axios';

export const api = createApiClient({
  baseURL: env('REACT_APP_API_URL'),
});

export const mockApi = createApiClient({
  baseURL: env('REACT_APP_API_MOCK_URL'),
});

export const budgetService = createApiClient({
  baseURL: env('REACT_APP_BUDGET_MS_URL'),
});

export const searchService = createApiClient({
  baseURL: env('REACT_APP_SEARCH_MS_URL'),
});
