import React, { useState, useCallback, useEffect } from 'react';
import ReactSelect from 'react-select';

import { api } from 'services/api';
import { isAxiosError } from 'services/axios';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import { Category } from 'types/access-control';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

interface ModalUserCategoryAddProps extends ModalRootProps {
  data: {
    user_id: number | null;
  };
}

const ModalUserCategoryAdd: React.FC<ModalUserCategoryAddProps> = ({
  onConfirm,
  handleClose,
  data,
  ...restProps
}) => {
  const { user_id } = data;

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState<FormErrors | undefined>();

  const [categorySelected, setCategorySelected] = useState<number[]>([]);

  const [categories, setCategories] = useState<Category[]>([]);

  const getCategories = useCallback(async () => {
    setLoading(true);
    setCategories([]);

    try {
      const response = await api.get(`/category`);

      const users = response.data;

      setCategories(users.data);
    } catch (err) {
      setCategories([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const categoriesToSelect = categories.map((cat) => ({
    value: cat.id,
    label: cat.name,
  }));

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (categorySelected.length === 0) {
      Object.assign(formErrors, { category: true, type: 'required' });
    }

    // if (!(await emailValidator.isValid(email))) {
    //   Object.assign(formErrors, { email: true, type: 'invalid' });
    // }

    // if (!role) {
    //   Object.assign(formErrors, { role: true, type: 'required' });
    // }

    setErrors(formErrors);

    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      const categoriesMap = categorySelected.map((cat) => ({
        category_id: cat,
      }));

      await api.post(`/user/${user_id}/category`, {
        categories: categoriesMap,
      });

      toast({
        description: `Equipes adicionada com sucesso.`,
        status: 'success',
      });

      if (onConfirm) onConfirm();
      handleClose();
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.data.message) {
          toast({
            description: err.response?.data.message,
            status: 'error',
          });
        } else {
          toast({
            description: 'Houve um erro ao tentar salvar as equipes',
            status: 'error',
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Vincular equipe ao usuário</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl isInvalid={!!errors?.category}>
            <FormLabel>Selecione as equipes</FormLabel>

            <ReactSelect
              placeholder="Selecione"
              options={categoriesToSelect}
              isMulti
              value={categoriesToSelect.filter((s) =>
                categorySelected.includes(s.value),
              )}
              isLoading={loading && categoriesToSelect.length === 0}
              isDisabled={loading || categoriesToSelect.length === 0}
              isClearable
              onChange={(selected) => {
                const value = selected.map((s) => s.value);
                setCategorySelected(value);
              }}
            />

            <FormErrorMessage>Selecione pelo menos uma equipe</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalUserCategoryAdd;
