import { UserOrganization } from 'types/access-control';

const roles = {
  'super-admin': {
    name: 'Super Admin',
    color: 'red',
  },
  'ecustos-admin': {
    name: 'Suporte',
    color: 'orange',
  },
  'organization-admin': {
    name: 'Administrador',
    color: 'red',
  },
  'organization-user': {
    name: 'Gestor',
    color: 'blue',
  },
  'group-admin': {
    name: 'Construtora',
    color: 'green',
  },
  manager: {
    name: 'Gerenciadora',
    color: 'purple',
  },
  default: {
    name: 'Usuário',
    color: 'gray',
  },
};

type Key = keyof typeof roles;

export const getColorByRole = (roleArray: string[]): string => {
  for (let i = 0; i < roleArray.length; i += 1) {
    if (roleArray.includes(roleArray[i])) {
      return roles[roleArray[i] as Key].color;
    }
  }

  return roles.default.color;
};

export const getRoleName = (roleArray: string[]): string => {
  for (let i = 0; i < roleArray.length; i += 1) {
    if (roleArray.includes(roleArray[i])) {
      return roles[roleArray[i] as Key].name;
    }
  }

  return roles.default.name;
};

export const mergeRoles = (userData: UserOrganization): string[] => {
  return [
    userData.roles ? [...userData.roles] : [],
    userData.managed_organization?.roles
      ? [...userData.managed_organization.roles]
      : [],
  ].flat();
};

export const getUserRoleName = (userData: UserOrganization): string => {
  const userRoles = mergeRoles(userData);
  return getRoleName(userRoles);
};

export const SUPER_ADMIN = 'super-admin';
export const ECUSTOS_ADMIN = 'ecustos-admin';
export const ORGANIZATION_ADMIN = 'organization-admin';
export const ORGANIZATION_USER = 'organization-user';
export const GROUP_ADMIN = 'group-admin';
export const MANAGER = 'manager';
export const DEFAULT = 'default';

const is =
  (role: string) =>
  (user: UserOrganization): boolean => {
    const userRoles = mergeRoles(user);
    return userRoles.includes(role);
  };

export const isSuperAdmin = is(SUPER_ADMIN);
export const isEcustosAdmin = is(ECUSTOS_ADMIN);
export const isOrganizationAdmin = is(ORGANIZATION_ADMIN);
export const isOrganizationUser = is(ORGANIZATION_USER);
export const isGroupAdmin = is(GROUP_ADMIN);
export const isManager = is(MANAGER);
export const isDefault = is(DEFAULT);

export const isBankAccount = (user: UserOrganization): boolean => {
  return isOrganizationAdmin(user) || isOrganizationUser(user);
};

export const isSuperOrEcustosAdmin = (user: UserOrganization): boolean => {
  return isSuperAdmin(user) || isEcustosAdmin(user);
};
export const isSuperOrEcustosAdminOrOrgAdmin = (
  user: UserOrganization,
): boolean => {
  return (
    isSuperAdmin(user) || isEcustosAdmin(user) || isOrganizationAdmin(user)
  );
};

export const createCanFunction =
  (user: UserOrganization) =>
  (guard: (user: UserOrganization) => boolean): boolean => {
    if (isSuperOrEcustosAdmin(user)) {
      return true;
    }

    return guard(user);
  };

export const isVerified = (user: UserOrganization): boolean => {
  return user.managed_organization.user.profile_verified;
};
