import React, { useState, useContext, useCallback, useEffect } from 'react';
import ReactSelect from 'react-select';

import { api } from 'services/api';
import { isAxiosError } from 'services/axios';
import { toast } from 'shared/toast';
import * as yup from 'yup';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  FormErrorMessage,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import AuthContext from 'contexts/AuthContext';

import { Category } from 'types/access-control';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

interface ModalUserInviteProps extends ModalRootProps {
  data: {
    group_id: number | null;
  };
}

const ModalUserInvite: React.FC<ModalUserInviteProps> = ({
  onConfirm,
  handleClose,
  data,
  ...restProps
}) => {
  const { bradescoId } = useContext(AuthContext);

  const emailValidator = yup.string().email();

  const { group_id } = data;

  const [email, setEmail] = useState<string>();
  const [role, setRole] = useState<string>(() =>
    group_id ? 'group-admin' : '',
  );

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState<FormErrors | undefined>();

  const [categorySelected, setCategorySelected] = useState<number[]>([]);

  const [categories, setCategories] = useState<Category[]>([]);

  const getCategories = useCallback(async () => {
    setLoading(true);
    setCategories([]);

    try {
      const response = await api.get(`/category`);

      const users = response.data;

      setCategories(users.data);
    } catch (err) {
      setCategories([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const categoriesToSelect = categories.map((cat) => ({
    value: cat.id,
    label: cat.name,
  }));

  const roleDescriptionByKey = (key: string): string => {
    switch (key) {
      case 'organization-admin':
      case 'group-admin':
        return 'Administrador';

      case 'organization-user':
        return 'Gestor';

      default:
        return key;
    }
  };

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (!email) {
      Object.assign(formErrors, { email: true, type: 'required' });
    }

    if (!(await emailValidator.isValid(email))) {
      Object.assign(formErrors, { email: true, type: 'invalid' });
    }

    if (!role) {
      Object.assign(formErrors, { role: true, type: 'required' });
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      await api.post(`/organization/${bradescoId}/user`, {
        email,
        role,
        group_id: group_id || null,
        organization_id: bradescoId,
      });

      toast({
        description: `Foi enviado um e-mail com o convite para ${email}.`,
        status: 'success',
      });

      if (onConfirm) onConfirm();
      handleClose();
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.data.message) {
          toast({
            description: err.response?.data.message,
            status: 'error',
          });
        } else {
          toast({
            description:
              'Houve um erro ao tentar enviar o e-mail. Verifique se o endereço está correto e tente novamente.',
            status: 'error',
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {group_id
            ? 'Adicionar usuário da construtora'
            : 'Adicionar usuário Bradesco'}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl isInvalid={!!errors?.email}>
            <FormLabel>E-mail</FormLabel>
            <Input
              w="100%"
              placeholder="Ex: joao@email.com"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
            />

            <FormErrorMessage>
              {!!errors?.email && errors?.type === 'invalid'
                ? 'E-mail inválido'
                : 'E-mail é obrigatório'}
            </FormErrorMessage>
          </FormControl>

          <FormControl marginTop="4" isInvalid={!!errors?.role}>
            <FormLabel>Permissão</FormLabel>
            <Menu>
              <MenuButton
                w="100%"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size="md"
                textAlign="left"
                borderColor={errors?.role ? 'red.500' : 'unset'}
                color={errors?.role ? 'white.200' : 'unset'}
                bgColor={errors?.role ? 'red.200' : 'gray.200'}
                _hover={{
                  bgColor: errors?.role ? 'red.200' : 'gray.200',
                }}
                _active={{
                  bgColor: errors?.role ? 'red.200' : 'gray.200',
                }}
              >
                {role ? roleDescriptionByKey(role) : 'Selecione'}
              </MenuButton>

              <MenuList maxW="100%" w="340px">
                {!group_id && (
                  <>
                    <MenuItem
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      onClick={() => {
                        setRole('organization-admin');
                      }}
                    >
                      <b>Administrador</b> Permissão para gerenciar todos os
                      orçamentos, não consegue editar um orçamento ou fazer
                      qualquer alteração, consegue criar novos usuários,
                      consegue criar construtoras. Aplicar bloqueios de acessos.
                    </MenuItem>

                    <MenuDivider />

                    <MenuItem
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      onClick={() => {
                        setRole('organization-user');
                      }}
                    >
                      <b>Gestor</b> Gerenciar os orçamentos, com aprovação e
                      devolutivas. Não tem permissão para rotinas
                      administrativas no sistemas ecustos.
                    </MenuItem>
                  </>
                )}

                {!!group_id && (
                  <MenuItem
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    onClick={() => {
                      setRole('group-admin');
                    }}
                  >
                    <b>Administrador</b> Crie orçamentos e acompanhe o fluxo de
                    aprovação.
                  </MenuItem>
                )}
              </MenuList>
            </Menu>

            <FormErrorMessage>Permissão é obrigatória</FormErrorMessage>
          </FormControl>

          {!group_id && (
            <FormControl marginTop="4">
              <FormLabel>Selecione as equipes</FormLabel>

              <ReactSelect
                placeholder="Selecione"
                options={categoriesToSelect}
                isMulti
                value={categoriesToSelect.filter((s) =>
                  categorySelected.includes(s.value),
                )}
                isLoading={loading && categoriesToSelect.length === 0}
                isDisabled={loading || categoriesToSelect.length === 0}
                isClearable
                onChange={(selected) => {
                  const value = selected.map((s) => s.value);
                  setCategorySelected(value);
                }}
              />

              <FormErrorMessage>
                Selecione pelo menos uma equipe
              </FormErrorMessage>
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalUserInvite;
