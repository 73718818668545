import React, { useCallback, useState } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import Form, { FormData } from './Form';

type Component = {
  type: 'composition_custom';
  quantity: number;
  composition: Omit<FormData, 'quantity' | 'total_value' | 'code'>;
};

type ComponentData = {
  parent_stage_id: number;
  components: Component[];
};

interface ModalCompositionPrices extends ModalRootProps {
  data: {
    subStageId: number;
    budgetId: number;
  };
}

const ModalBudgetCustomCompositionAdd: React.FC<ModalCompositionPrices> = ({
  data: { subStageId, budgetId },
  handleClose,
  onConfirm,
  ...restProps
}) => {
  const [loading, setLoading] = useState(false);

  const prepareComponents = useCallback(
    (data: FormData): ComponentData => {
      return {
        parent_stage_id: subStageId,
        components: [
          {
            type: 'composition_custom',
            quantity: Number(String(data.quantity).replace(',', '.')),
            composition: {
              type: 'separate',
              description: data.description,
              unit_measure_id: data.unit_measure_id,
              immobilized: data.immobilized,
              unit_price: data.unit_price,
              labor: data.labor,
              material: data.material,
              labor_percent: data.labor_percent,
              material_percent: data.material_percent,
              equipment: 0,
              refund: data.refund,
              third_party_services: 0,
              other: 0,
            },
          },
        ],
      };
    },
    [subStageId],
  );

  const handleSubmitComponent = useCallback(
    async (data: FormData) => {
      setLoading(true);

      try {
        await api.post(`budget/${budgetId}/component`, {
          ...prepareComponents(data),
        });

        toast({
          description: 'Item salvo com sucesso!',
          status: 'success',
        });

        if (onConfirm) onConfirm();
        handleClose();
      } catch (err) {
        toast({
          description: 'Houve um erro ao salvar o item!',
          status: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [prepareComponents, budgetId, handleClose, onConfirm],
  );

  return (
    <Modal {...restProps} size="6xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar item complementar</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Form isSaving={loading} onSubmit={handleSubmitComponent} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalBudgetCustomCompositionAdd;
