import React, { useRef } from 'react';
import { ColumnInstance } from 'react-table';

import { Tr, Td, TableRowProps } from '@chakra-ui/react';

import { Fragment, Row, SinapiCompositionFragment } from './types';

interface RowProps {
  visibleColumns: ColumnInstance<Fragment>[];
  level: number;
  renderRowSubComponent: (params: {
    nextComponentId: number;
  }) => React.ReactElement;
  row: Row;
}

const FragmentTableRow: React.FC<RowProps> = ({
  visibleColumns,
  level,
  renderRowSubComponent,
  row,
  ...restProps
}) => {
  const ref = useRef<HTMLTableRowElement>(null);

  function getType(tableRow: Row): 'composition' | 'input' {
    return tableRow.original.type;
  }

  function isComposition(data: Fragment): data is SinapiCompositionFragment {
    return data.type === 'composition';
  }

  function getCustomRowExpandedProps(tableRow: Row): TableRowProps {
    return {
      ...row.getToggleRowExpandedProps(),
      title: 'Detalhar composição',
      backgroundColor: tableRow.isExpanded ? 'brand.400' : 'inherit',
      color: tableRow.isExpanded ? 'white' : 'gray.800',
      _hover: {
        color: tableRow.isExpanded ? 'white' : 'gray.800',
        backgroundColor: tableRow.isExpanded ? 'brand.400' : 'gray.200',
      },
    };
  }

  return (
    <>
      <Tr
        ref={ref}
        {...row.getRowProps()}
        {...(getType(row) === 'composition' && getCustomRowExpandedProps(row))}
        {...restProps}
      >
        {row.cells.map((cell) => {
          return (
            <Td
              {...cell.getCellProps()}
              isNumeric={cell.column.isNumeric}
              borderBottomColor={row.isExpanded ? 'brand.400' : undefined}
            >
              {cell.render('Cell')}
            </Td>
          );
        })}
      </Tr>

      {row.isExpanded ? (
        <Tr
          backgroundColor={row.isExpanded ? 'brand.100' : 'inherit'}
          border={level > 0 ? '1px solid' : undefined}
          borderColor={level > 0 ? 'white' : undefined}
          sx={{
            '* > table': {
              backgroundColor: 'white',
              borderBottom: 'none',
            },
          }}
        >
          <Td colSpan={visibleColumns.length} paddingY={4} borderBottom="none">
            {isComposition(row.original) &&
              renderRowSubComponent({
                nextComponentId: row.original.data.composition_id.id,
              })}
          </Td>
        </Tr>
      ) : null}
    </>
  );
};

export default FragmentTableRow;
