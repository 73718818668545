import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { PersonRemove as PersonRemoveIcon } from '@styled-icons/material/PersonRemove';
import { PersonSearch as PersonSearchIcon } from '@styled-icons/material/PersonSearch';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  FormErrorMessage,
  Flex,
  Text,
  Grid,
  Textarea,
  HStack,
  Box,
  keyframes,
  InputGroup,
  Input,
  InputRightAddon,
  IconButton,
  Icon,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import useModal from 'hooks/useModal';

import { Group } from 'types/access-control';
import { Budget } from 'types/budget';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

type Action = Budget['flow_status']['actions'][number];

interface ModalManageFlowProps extends ModalRootProps {
  data: {
    budget: Budget;
  };
}

const spin = keyframes`
  0% {opacity: 0; scale: 0;}
  50% {opacity: 0.3;  scale: 1;}
  100% {opacity: 0;  scale: 0;}
`;

const ModalManageFlow: React.FC<ModalManageFlowProps> = ({
  onConfirm,
  handleClose,
  data,
  ...restProps
}) => {
  const { budget } = data;

  const { openModal } = useModal();

  const [selectedAction, setSelectedAction] = useState<Action>();
  const isResquestReview =
    selectedAction?.key === 'budget_request_manager_review';

  const [observation, setObservation] = useState<string>();

  const [actions, setData] = useState<Action[]>([]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrors | undefined>();

  const [manager, setManager] = useState<Group>({} as Group);
  const isManagerSelected = Object.keys(manager).length > 0;

  const spinAnimation = `${spin} infinite 2s ease`;

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get(
        `flow/status/${budget.flow_status.id}/action`,
      );

      const actionList = response.data;

      setData(actionList.data);
    } catch (err) {
      toast({
        description: 'Houve um erro ao carregar as ações!',
        status: 'error',
      });
      setData([]);
    } finally {
      setLoading(false);
    }
  }, [budget]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getGroupName = (group: Group): string => {
    if (group.document_type === 'cnpj') {
      return group.fantasy_name
        ? `${group.corporate_name} - ${group.fantasy_name}`
        : group.corporate_name;
    }

    return group.name;
  };

  const handleConfirm = async (): Promise<void> => {
    const formErrors = {} as FormErrors;

    if (!selectedAction) {
      Object.assign(formErrors, { selectedAction: true, type: 'required' });
    }

    if (!observation) {
      Object.assign(formErrors, { observation: true, type: 'required' });
    }

    if (isResquestReview && !isManagerSelected) {
      Object.assign(formErrors, { manager: true, type: 'required' });
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      await api.post(`/flow/action/${selectedAction?.id}`, {
        object_type: 'budget',
        object_id: budget.id,
        flow_key: 'use_flow',
        type: 'manual',
        message:
          isResquestReview && isManagerSelected
            ? `${observation} / Gerenciadora = ${getGroupName(manager)}`
            : observation,
      });

      if (isResquestReview && isManagerSelected) {
        await api.put(`/budget/${budget.id}/manager/${manager.id}`);
      }

      toast({
        description: `Status atualizado com sucesso!`,
        status: 'success',
      });

      if (onConfirm) onConfirm();
      handleClose();
    } catch (err) {
      toast({
        description:
          err.response?.data?.message || 'Houve um erro ao performar a ação!',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Atualizar status do orçamento</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Grid
            w="100%"
            templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
            columnGap={2}
            rowGap={4}
            paddingY={2}
            borderTop="1px solid rgba(0,0,0,0.1)"
            borderBottom="1px solid rgba(0,0,0,0.1)"
          >
            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Descrição: </Text>
              <Text
                noOfLines={1}
                textOverflow="ellipsis"
                title={budget.identification?.description}
              >
                {budget.identification.description || 'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Status atual: </Text>
              <Text noOfLines={1} textOverflow="ellipsis">
                {budget.flow_status?.name || 'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Nº do Projeto (SAP): </Text>
              <Text noOfLines={1} textOverflow="ellipsis">
                {budget.identification.project_number || 'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Agência / Junção / Departamento: </Text>
              <Text noOfLines={1} textOverflow="ellipsis">
                {budget.identification.sector_code || 'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Serviço: </Text>
              <Text noOfLines={1} textOverflow="ellipsis">
                {budget.identification.service_description ||
                  'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Área de intervenção: </Text>
              <Text noOfLines={1} textOverflow="ellipsis">
                {budget.identification.intervention_area || 'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Total: </Text>
              <Text>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(budget.total)}{' '}
                {`(${Intl.NumberFormat('pt-BR', {
                  maximumFractionDigits: 2,
                }).format(
                  budget.total_separate_percent || 0,
                )}% complementares)`}
              </Text>
            </Flex>
          </Grid>

          <FormControl marginTop="4" isInvalid={!!errors?.selectedAction}>
            <FormLabel>Ação</FormLabel>
            <Menu size="lg" isLazy>
              <MenuButton
                w="100%"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size="md"
                textAlign="left"
                disabled={loading}
                borderColor={errors?.selectedAction ? 'red.500' : 'unset'}
                color={errors?.selectedAction ? 'white.200' : 'unset'}
                bgColor={errors?.selectedAction ? 'red.200' : 'gray.200'}
                _hover={{
                  bgColor: errors?.selectedAction ? 'red.200' : 'gray.200',
                }}
                _active={{
                  bgColor: errors?.selectedAction ? 'red.200' : 'gray.200',
                }}
              >
                {selectedAction ? selectedAction.name : 'Selecione'}
              </MenuButton>

              <MenuList>
                {actions.map((item, i, array) => (
                  <Fragment key={item.id}>
                    <MenuItem
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      pr={{ base: 2, md: 96 }}
                      onClick={() => {
                        setSelectedAction(item);
                      }}
                    >
                      <Flex direction="row" alignItems="center">
                        <Box
                          width={3}
                          height={3}
                          borderRadius="50%"
                          bg={item.color}
                          position="relative"
                          boxShadow="base"
                          flexShrink={0}
                          _before={{
                            content: '""',
                            position: 'absolute',
                            top: -1,
                            left: -1,
                            right: -1,
                            bottom: -1,
                            bg: item.color,
                            opacity: 0.3,
                            borderRadius: '50%',
                            animation: spinAnimation,
                          }}
                          marginRight={2}
                        />
                        <b>{item.name}</b>
                      </Flex>
                      {item.description}
                    </MenuItem>

                    {i !== array.length - 1 && <MenuDivider />}
                  </Fragment>
                ))}

                {actions.length === 0 && (
                  <MenuItem
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <b>Nenhuma ação disponível</b>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>

            <FormErrorMessage>Ação é obrigatória</FormErrorMessage>
          </FormControl>

          {isResquestReview && (
            <FormControl mt={4} isInvalid={!!errors?.manager}>
              <FormLabel>Gerenciadora</FormLabel>

              <InputGroup>
                <Input
                  type="text"
                  mb={{ base: 3, md: 0 }}
                  isReadOnly
                  _readOnly={{ backgroundColor: 'white' }}
                  value={
                    (isManagerSelected &&
                      (manager.document_type === 'cpf'
                        ? manager.name
                        : manager.corporate_name)) ||
                    ''
                  }
                />

                <InputRightAddon px={0}>
                  <IconButton
                    height="100%"
                    colorScheme="green"
                    aria-label="Pesquisar gerenciadora"
                    title="Pesquisar gerenciadora"
                    borderRadius={!isManagerSelected ? 'base' : '0'}
                    borderTopLeftRadius="0"
                    borderBottomLeftRadius="0"
                    width="50px"
                    onClick={() =>
                      openModal('groupLookup', {
                        mode: 'lookup',
                        onConfirm: (group) => {
                          setManager(group);
                        },
                      })
                    }
                  >
                    <Icon as={PersonSearchIcon} />
                  </IconButton>
                </InputRightAddon>

                {isManagerSelected && (
                  <InputRightAddon px={0}>
                    <IconButton
                      height="100%"
                      colorScheme="red"
                      aria-label="Remover gerenciadora"
                      title="Remover gerenciadora"
                      borderTopLeftRadius="0"
                      borderBottomLeftRadius="0"
                      width="50px"
                      onClick={() => {
                        setManager({} as Group);
                      }}
                    >
                      <Icon as={PersonRemoveIcon} />
                    </IconButton>
                  </InputRightAddon>
                )}
              </InputGroup>

              <FormErrorMessage>Gerenciadora é obrigatória</FormErrorMessage>
            </FormControl>
          )}

          <FormControl marginTop="4" isInvalid={!!errors?.observation}>
            <FormLabel>Justificativa</FormLabel>
            <Textarea
              resize="none"
              onChange={(e) => setObservation(e.target.value)}
            />
            <FormErrorMessage>Justificativa é obrigatória</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            <Button
              isLoading={loading}
              isDisabled={loading}
              onClick={() =>
                openModal('budgetHistory', {
                  mode: 'lookup',
                  data: {
                    budget,
                  },
                })
              }
            >
              Ver histórico
            </Button>

            <Button
              isLoading={loading}
              isDisabled={loading}
              colorScheme="green"
              onClick={handleConfirm}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalManageFlow;
