import React, { useState, useEffect, useCallback, useContext } from 'react';

import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { api } from 'services/api';
import { formatDate } from 'shared/date';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  HStack,
  Flex,
  Input,
  Select,
  Button,
  Icon,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

import AppTable from 'components/AppTable';
import { ModalRootProps } from 'components/Modal/Root';
import PaginationWrapper from 'components/Pagination';

import AuthContext from 'contexts/AuthContext';

import useDebouncedState from 'hooks/useDebouncedState';
import useThrottledState from 'hooks/useThrottledState';

import { Group } from 'types/access-control';
import { Pagination, ServicePagination } from 'types/pagination';

const modalMargin = 120;
const modalMobileFix = 100;

const ModalClientLookup: React.FC<ModalRootProps> = ({
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const { bradescoId } = useContext(AuthContext);

  const [isMobile] = useMediaQuery('(max-width: 728px)');

  const [data, setData] = useState<Group[]>([]);
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useDebouncedState<string>(
    (() => {
      return '';
    })(),
  );

  const [pagination, setPagination] = useThrottledState<Pagination>(
    (() => {
      return { per_page: 20, page: 1 };
    })(),
    1000,
  );

  const [sort, setSort] = useState<string>(
    (() => {
      return '';
    })(),
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get(`/group`, {
        params: {
          'filter[keyword]': keyword,
          'filter[organization_id]': bradescoId,
          'filter[type]': 'manager',
          page: pagination.page,
          per_page: pagination.per_page,
          sort,
        },
      });

      const managers = response.data.data;
      const paginationObj = response.data.meta;

      const newPagination = {
        last_page: paginationObj.last_page,
      };

      setData(managers);
      setServicePagination(newPagination);
    } catch (err) {
      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [keyword, pagination, sort, bradescoId]);

  useEffect(() => {
    if (pagination.page > servicePagination?.last_page) {
      setPagination((oldPagination) => {
        if (oldPagination.page > 1) {
          return {
            ...oldPagination,
            page: 1,
          };
        }

        return oldPagination;
      });
    }
  }, [pagination.page, servicePagination, setPagination]);

  const getGroupName = (group: Group): string => {
    if (group.document_type === 'cnpj') {
      return group.fantasy_name
        ? `${group.corporate_name} - ${group.fantasy_name}`
        : group.corporate_name;
    }

    return group.name;
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const handleConfirm = (group: Group): void => {
    if (onConfirm) onConfirm(group);
    handleClose();
  };

  return (
    <Modal {...restProps} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        sx={{
          width: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxWidth: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          height: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxHeight: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
        }}
      >
        <ModalHeader>Selecionar gerenciadora</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} mt={3}>
            <HStack mb={3}>
              <Text fontSize="smaller">Por página:</Text>
              <Select
                width="auto"
                defaultValue={pagination.per_page}
                onChange={(e) => {
                  setPagination({
                    ...pagination,
                    per_page: Number(e.target.value),
                  });
                }}
              >
                {[5, 10, 20, 50, 100].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </HStack>

            <Flex
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              mb={3}
              gridGap={{ base: 0, md: 3 }}
            >
              <Input
                type="text"
                placeholder="Pesquisar"
                defaultValue={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                mb={{ base: 3, md: 0 }}
              />
            </Flex>
          </SimpleGrid>

          <AppTable
            cols={[
              { field: 'name', description: 'Nome', sortable: false },
              { field: 'responsible_email', description: 'E-mail' },
              { field: 'city', description: 'Cidade / UF' },
              {
                field: 'created_at',
                description: 'Data de Cadastro',
                isCentered: true,
              },
              {
                field: 'total_users',
                description: 'Usuários',
                sortable: false,
                isNumeric: true,
              },
              { field: 'actions', description: 'Ações' },
            ]}
            mapping={(item) => ({
              id: item.id,
              name: (
                <>
                  <Text mb={1}>{getGroupName(item)}</Text>

                  <Text fontWeight="bold" fontSize="smaller">
                    {item.document}
                  </Text>
                </>
              ),
              responsible_email: item.responsible_email,
              city: (
                <>
                  {item.city_id.name} / {item.city_id.state.abbreviation}
                </>
              ),
              created_at: formatDate(item.created_at),
              total_users: Intl.NumberFormat('pt-BR').format(
                item.total_users || 0,
              ),
              actions: (
                <Button
                  colorScheme="green"
                  leftIcon={<Icon as={CheckIcon} />}
                  onClick={() => handleConfirm(item)}
                >
                  Selecionar
                </Button>
              ),
            })}
            data={data}
            loading={loading}
            initialSort={sort}
            onUpdateSort={(s) => setSort(s)}
          />

          <PaginationWrapper
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalClientLookup;
