import React, { useState, useEffect, useCallback } from 'react';

// import { searchService } from 'services/api';

import { mockApi } from 'services/api';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Select,
  Button,
  ModalFooter,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import { Base } from 'types/base';

const ModalBaseSelect: React.FC<ModalRootProps> = ({
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const [bases, setBases] = useState<Base[]>([]);
  const [loading, setLoading] = useState(false);

  const [selectedBase, setSelectedBase] = useState<number>();

  const getBases = useCallback(async () => {
    setLoading(true);
    setBases([]);

    try {
      const response = await mockApi.get('/bases');

      const basesData = response.data;

      setBases(basesData);
    } catch (err) {
      setBases([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getBases();
  }, [getBases]);

  const handleConfirm = (): void => {
    const base = bases.find((item) => item.id === selectedBase);
    if (onConfirm) onConfirm(base);
    handleClose();
  };

  return (
    <Modal {...restProps} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Selecionar base de dados</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Select
            placeholder="Selecione"
            onChange={(e) => setSelectedBase(Number(e.target.value))}
            isDisabled={bases.length === 0}
            _disabled={{
              background: 'hsl(0, 0%, 95%)',
              border: '1px solid hsl(0, 0%, 90%)',
            }}
          >
            {bases.map((base) => (
              <option key={base.id} value={base.id}>
                {base.description}
              </option>
            ))}
          </Select>
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Selecionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalBaseSelect;
