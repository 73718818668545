import React, { useState, useContext } from 'react';

import { api } from 'services/api';
import { isAxiosError } from 'services/axios';
import { toast } from 'shared/toast';
import * as yup from 'yup';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  FormErrorMessage,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import AuthContext from 'contexts/AuthContext';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

interface ModalManagerInviteProps extends ModalRootProps {
  data: {
    group_id: number;
  };
}

const ModalManagerInvite: React.FC<ModalManagerInviteProps> = ({
  onConfirm,
  handleClose,
  data,
  ...restProps
}) => {
  const { bradescoId } = useContext(AuthContext);

  const emailValidator = yup.string().email();

  const { group_id } = data;

  const [email, setEmail] = useState<string>();
  const [role, setRole] = useState<string>('manager');

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState<FormErrors | undefined>();

  const roleDescriptionByKey = (key: string): string => {
    switch (key) {
      case 'manager':
        return 'Gerenciadora';

      default:
        return key;
    }
  };

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (!email) {
      Object.assign(formErrors, { email: true, type: 'required' });
    }

    if (!(await emailValidator.isValid(email))) {
      Object.assign(formErrors, { email: true, type: 'invalid' });
    }

    if (!role) {
      Object.assign(formErrors, { role: true, type: 'required' });
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      await api.post(`/organization/${bradescoId}/user`, {
        email,
        role,
        group_id,
        organization_id: bradescoId,
      });

      toast({
        description: `Foi enviado um e-mail com o convite para ${email}.`,
        status: 'success',
      });

      if (onConfirm) onConfirm();
      handleClose();
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.data.message) {
          toast({
            description: err.response?.data.message,
            status: 'error',
          });
        } else {
          toast({
            description:
              'Houve um erro ao tentar enviar o e-mail. Verifique se o endereço está correto e tente novamente.',
            status: 'error',
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar usuário da gerenciadora</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl isInvalid={!!errors?.email}>
            <FormLabel>E-mail</FormLabel>
            <Input
              w="100%"
              placeholder="Ex: joao@email.com"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
            />

            <FormErrorMessage>
              {!!errors?.email && errors?.type === 'invalid'
                ? 'E-mail inválido'
                : 'E-mail é obrigatório'}
            </FormErrorMessage>
          </FormControl>

          <FormControl marginTop="4" isInvalid={!!errors?.role}>
            <FormLabel>Permissão</FormLabel>
            <Menu>
              <MenuButton
                w="100%"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size="md"
                textAlign="left"
                borderColor={errors?.role ? 'red.500' : 'unset'}
                color={errors?.role ? 'white.200' : 'unset'}
                bgColor={errors?.role ? 'red.200' : 'gray.200'}
                _hover={{
                  bgColor: errors?.role ? 'red.200' : 'gray.200',
                }}
                _active={{
                  bgColor: errors?.role ? 'red.200' : 'gray.200',
                }}
              >
                {role ? roleDescriptionByKey(role) : 'Selecione'}
              </MenuButton>

              <MenuList maxW="100%" w="340px">
                <MenuItem
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  onClick={() => {
                    setRole('manager');
                  }}
                >
                  <b>Gerenciadora</b> Gerencie e avalie os orçamentos da
                  plataforma
                </MenuItem>
              </MenuList>
            </Menu>

            <FormErrorMessage>Permissão é obrigatória</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalManagerInvite;
