import React, { useCallback, useEffect, useState } from 'react';

import { api } from 'services/api';
import { formatDate } from 'shared/date';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Flex,
  Text,
  Grid,
  HStack,
  useMediaQuery,
} from '@chakra-ui/react';

import AppTable from 'components/AppTable';
import { ModalRootProps } from 'components/Modal/Root';
import PaginationWrapper from 'components/Pagination';

import useThrottledState from 'hooks/useThrottledState';

import { User } from 'types/access-control';
import { Budget } from 'types/budget';
import { Pagination, ServicePagination } from 'types/pagination';

import FlowStatusBadge from './StatusBadge';

interface ModalManageFlowProps extends ModalRootProps {
  data: {
    budget: Budget;
  };
}

type FlowHistory = {
  id: number;
  type: string;
  user: {
    id: number;
    name: string;
    email: string;
    profile_verified: true;
    corporate_name: null;
    fantasy_name: null;
    document_type: null;
    document: null;
    address: null;
    number: null;
    complement: null;
    district: null;
    zip_code: null;
    city: null;
    created_at: string;
    updated_at: string;
    deleted_at: null;
  };
  object: {
    status: {
      color: string;
      icon: string;
      name: string;
    };
  };
  status: {
    color: string;
    icon: string;
    name: string;
  };
  message: string;
  created_at: string;
  updated_at: string;
};

type FlowUser = FlowHistory['user'];

const modalMargin = 120;
const modalMobileFix = 100;

const ModalManageFlow: React.FC<ModalManageFlowProps> = ({
  handleClose,
  data: budgetData,
  ...restProps
}) => {
  const { budget } = budgetData;

  const [isMobile] = useMediaQuery('(max-width: 728px)');

  const [data, setData] = useState<FlowHistory[]>([]);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useThrottledState<Pagination>(
    (() => {
      return {
        per_page: 20,
        page: 1,
      };
    })(),
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get('/flow/history', {
        params: {
          'filter[object_id]': budget.id,
          'filter[object_type]': 'budget',
          page: pagination.page,
          per_page: pagination.per_page,
          sort: '-created_at',
        },
      });

      const history = response.data;

      const newPagination = {
        last_page: history.meta.last_page,
      };

      setData(history.data);
      setServicePagination(newPagination);
    } catch (err) {
      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [pagination, budget]);

  useEffect(() => {
    if (pagination.page > servicePagination?.last_page) {
      setPagination((oldPagination) => {
        if (oldPagination.page > 1) {
          return {
            ...oldPagination,
            page: 1,
          };
        }

        return oldPagination;
      });
    }
  }, [pagination.page, servicePagination, setPagination]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleConfirm = (): void => {
    handleClose();
  };

  const getBudgetUserName = (item: User): string => {
    if (item.document_type === 'cnpj') {
      return `${item.corporate_name} - ${item.fantasy_name}`;
    }

    return item.name;
  };

  const getUserName = (item: FlowUser): string => {
    if (item.document_type === 'cnpj') {
      return `${item.corporate_name} - ${item.fantasy_name}`;
    }

    return item.name;
  };

  const getBuilderName = (item: Budget): string => {
    if (item.builder && item.builder.corporate_name) {
      return item.builder.corporate_name;
    }

    if (item.builder && item.builder.name) {
      return item.builder.name;
    }

    return '';
  };

  return (
    <Modal {...restProps} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        sx={{
          width: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxWidth: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          height: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxHeight: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
        }}
      >
        <ModalHeader>Histórico de aprovação</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Grid
            w="100%"
            templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
            columnGap={2}
            rowGap={4}
            paddingY={4}
            borderTop="1px solid rgba(0,0,0,0.1)"
            marginBottom={4}
          >
            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Descrição: </Text>
              <Text
                noOfLines={1}
                textOverflow="ellipsis"
                title={budget.identification?.description}
              >
                {budget.identification.description || 'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Status atual: </Text>
              <Text noOfLines={1} textOverflow="ellipsis">
                {budget.flow_status?.name || 'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Criação: </Text>
              <Text noOfLines={1} textOverflow="ellipsis">
                {formatDate(budget.created_at)}
              </Text>
            </Flex>

            {!!budget.user && (
              <>
                {budget.builder && (
                  <Flex direction="row" gap={1}>
                    <Text fontWeight="bold">Construtora: </Text>
                    <Text>{getBuilderName(budget)}</Text>
                  </Flex>
                )}

                <Flex direction="row" gap={1}>
                  <Text fontWeight="bold">Usuário: </Text>
                  <Text>{getBudgetUserName(budget.user)}</Text>
                </Flex>
              </>
            )}

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Total: </Text>
              <Text>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(budget.total)}{' '}
                {`(${Intl.NumberFormat('pt-BR', {
                  maximumFractionDigits: 2,
                }).format(
                  budget.total_separate_percent || 0,
                )}% complementares)`}
              </Text>
            </Flex>
          </Grid>

          <AppTable
            cols={[
              {
                field: 'responsible',
                description: 'Responsável',
                sortable: false,
              },
              { field: 'status', description: 'Status', sortable: false },
              { field: 'message', description: 'Mensagem', sortable: false },
            ]}
            mapping={(item) => ({
              id: item.id,
              responsible: (
                <>
                  <Text mb={1}>{formatDate(item.created_at)}</Text>

                  <Text fontWeight="bold" fontSize="smaller">
                    {getUserName(item.user)}
                  </Text>
                </>
              ),
              status: (
                <FlowStatusBadge
                  color={item.status.color}
                  description={item.status.name}
                />
              ),
              message: <Text whiteSpace="pre-wrap">{item.message}</Text>,
              actions: null,
            })}
            data={data}
            noDataMessage="Nenhuma ação registrada."
            loading={loading}
            onUpdateSort={() => {
              //
            }}
          />

          <PaginationWrapper
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            <Button
              isLoading={loading}
              isDisabled={loading}
              onClick={handleConfirm}
            >
              Fechar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalManageFlow;
