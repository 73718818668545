import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';

import { Filter as FilterIcon } from '@styled-icons/fa-solid/Filter';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Button,
  Icon,
  Grid,
  FormControl,
  FormLabel,
  Flex,
  Input,
  // Select,
  Text,
} from '@chakra-ui/react';

import { UnitMeasure } from 'types/base';

interface Qs {
  [key: string]: string | number | null | undefined;
}

export interface FilterHandles {
  open: () => void;
}

export interface FilterData extends Qs {
  unit_measure_id: number | null;
  description: string;
}

interface FormProps {
  capabilities: {
    base_id: number;
    blocked: boolean;
    type_id: number;
    version_id: number;
    type_title: string;
    classification_id: number;
    classification_title: string;
  };
  onSubmit(data: FilterData): void;
  loading?: boolean;
}

const SearchFilter: React.FC<FormProps> = ({
  capabilities,
  onSubmit,
  loading = false,
}) => {
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const { handleSubmit, setValue, register, control } = useForm<FilterData>();

  const [unitMeasures, setUnitMeasures] = useState<UnitMeasure[]>([]);
  const [selectedUnitMeasure, setSelectedUnitMeasure] = useState<number>();

  const prepareSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data,
      });
    },
    [onSubmit],
  );

  const getUnitMeasures = useCallback(async () => {
    setIsLoadingFilters(true);

    try {
      const response = await api.get('/unit/measure/list');
      const unitMeasuresLoaded = response.data;
      setUnitMeasures(unitMeasuresLoaded.data);
    } catch (err) {
      toast({
        description: 'Houve um erro ao carregar as unidades de medida!',
        status: 'error',
      });
    } finally {
      setIsLoadingFilters(false);
    }
  }, []);

  const unitMeasuresToSelect = useMemo(() => {
    return unitMeasures.map((unitMeasure) => ({
      value: String(unitMeasure.id),
      label: unitMeasure.description,
    }));
  }, [unitMeasures]);

  useEffect(() => {
    getUnitMeasures();
  }, [getUnitMeasures]);

  return (
    <Flex
      w="100%"
      as="form"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        '> *': {
          marginY: 1,
        },
      }}
      onSubmit={handleSubmit(prepareSubmit)}
    >
      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={4}
      >
        <FormControl>
          <FormLabel>Classe da composição</FormLabel>
          <Text fontSize="small" fontWeight="bold">
            {capabilities.classification_title}
          </Text>
        </FormControl>

        <FormControl>
          <FormLabel>Tipo da composição</FormLabel>
          <Text fontSize="small" fontWeight="bold">
            {capabilities.type_title}
          </Text>
        </FormControl>

        <FormControl>
          <FormLabel>Unidade de medida</FormLabel>

          <Controller
            render={(field) => (
              <ReactSelect
                {...field}
                placeholder="Selecione"
                maxMenuHeight={200}
                options={unitMeasuresToSelect}
                value={unitMeasuresToSelect.filter(
                  (s) => Number(s.value) === selectedUnitMeasure,
                )}
                isLoading={isLoadingFilters}
                isDisabled={unitMeasuresToSelect.length === 0}
                isClearable
                onChange={(selected) => {
                  const value = selected ? Number(selected.value) : undefined;
                  setSelectedUnitMeasure(value);
                  setValue('unit_measure_id', Number(value));
                }}
              />
            )}
            name="unit_measure_id"
            control={control}
          />
        </FormControl>
      </Grid>

      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={4}
      >
        <FormControl>
          <FormLabel>Pesquisar por descrição / código da composição</FormLabel>

          <Input {...register('description')} />
        </FormControl>
      </Grid>

      <Button
        w="100%"
        type="submit"
        colorScheme="blue"
        isLoading={loading}
        leftIcon={<Icon as={FilterIcon} />}
        mt="4"
      >
        Filtrar
      </Button>
    </Flex>
  );
};

export default SearchFilter;
