import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Text,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

interface ModalUserInviteProps extends ModalRootProps {
  data: {
    group_id: number | null;
  };
}

const ModalUserInvite: React.FC<ModalUserInviteProps> = ({
  handleClose,
  ...restProps
}) => {
  return (
    <Modal size="xl" scrollBehavior="inside" {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Alteração de % de faturamento - Termo de ciência
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text lineHeight="7" textAlign="justify">
            A Construtora/CONTRATADA declara-se ciente de que deve cumprir, na
            integra, a legislação tributária aplicável aos serviços prestados em
            favor do CONTRATANTE, tudo em conformidade com as suas declarações e
            obrigações contratuais assumidas junto ao CONTRATO celebrado entre
            as Partes
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" onClick={handleClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalUserInvite;
