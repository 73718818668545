import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Box,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import FragmentsTable from './FragmentsTable';

type Props = ModalRootProps & {
  data: {
    id: number;
    locale_id: number;
    price_type_id: number;
    code: string;
    description: string;
    prices: string;
    classification: string;
    type: string;
    unit_measure: string;
  };
};

const ModalCompositionLookup: React.FC<Props> = ({ data, ...restProps }) => {
  const price: number = (() => {
    const prices = JSON.parse(data.prices);
    const states = Array.from(Object.keys(prices));
    const state = states[0];

    const price_ = prices[state];

    if (price_.taxed) {
      return price_.taxed.total;
    }

    if (price_.untaxed) {
      return price_.untaxed.total;
    }

    return 0;
  })();

  return (
    <Modal {...restProps} size="full" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Detalhes da composição</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Flex
            width="100%"
            my={4}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width={{ base: '100%', md: '8%' }}
            >
              <Text fontWeight="600">Código</Text>
              <Text fontSize="smaller">{data.code}</Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Descrição</Text>
              <Text fontSize="smaller" fontStyle="italic">
                {data.description}
              </Text>
            </Box>
          </Flex>

          <Flex
            width="100%"
            my={4}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Valor unitário</Text>

              <Text as="span" fontSize="smaller" fontStyle="italic">
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(price)}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Classe</Text>
              <Text fontSize="smaller" fontStyle="italic">
                {data.classification}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Tipo</Text>
              <Text fontSize="smaller" fontStyle="italic">
                {data.type}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Unidade</Text>
              <Text fontSize="smaller" fontStyle="italic">
                {data.unit_measure}
              </Text>
            </Box>
          </Flex>

          <FragmentsTable
            component_id={data.id}
            locale_id={data.locale_id}
            price_type_id={data.price_type_id}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalCompositionLookup;
