import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useExpanded, useTable } from 'react-table';

import { DownArrow as DownArrowIcon } from '@styled-icons/boxicons-solid/DownArrow';
import { RightArrow as RightArrowIcon } from '@styled-icons/boxicons-solid/RightArrow';
import { MdiReactIconComponentType } from 'mdi-react';
import BricksIcon from 'mdi-react/BricksIcon';
import GroupIcon from 'mdi-react/GroupIcon';
import InfoIcon from 'mdi-react/InfoCircleOutlineIcon';
import ShovelIcon from 'mdi-react/ShovelIcon';
import { searchService } from 'services/api';
import { toast } from 'shared/toast';

import {
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  HStack,
  SimpleGrid,
  Select,
  Flex,
  Box,
} from '@chakra-ui/react';

import Loader from 'components/Loader';
import PaginationWrapper from 'components/Pagination';

import useDebouncedState from 'hooks/useDebouncedState';
import useThrottledState from 'hooks/useThrottledState';

import { Pagination, ServicePagination } from 'types/pagination';

import FragmentTableRow from './FragmentsTableRow';
import {
  CompositionComponent,
  Fragment,
  Row,
  SinapiInputFragment,
} from './types';

type Props = {
  component_id: number;
  locale_id: number;
  price_type_id: number;
  isSubRow?: boolean;
  level?: number;
};

type Summary = {
  component: CompositionComponent;
  inputs: number;
  compositions: number;
};

type ResponseFragment = {
  type: Fragment['type'];
  data: {
    '0': Fragment['data'];
    coefficient: number;
    total: number;
  };
};

type Response = Array<ResponseFragment>;

const FragmentsTable: React.FC<Props> = ({
  isSubRow = false,
  level = 0,
  component_id,
  locale_id,
  price_type_id,
}) => {
  const [fragments, setFragments] = useState<Fragment[]>([]);
  const [summary, setSummary] = useState({} as Summary);
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useDebouncedState<string>('');
  const [pagination, setPagination] = useThrottledState<Pagination>(
    (() => {
      return {
        per_page: 5,
        page: 1,
      };
    })(),
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const prepareData = (fragmentArray: Fragment[]): Fragment[] => {
    const fragmentsWithId = fragmentArray.map((fragment, index) => {
      return {
        ...fragment,
        id: index + 1,
      };
    });

    return fragmentsWithId;
  };

  const normalizeResponse = useCallback((response: Response): Fragment[] => {
    const normalizedResponse = Array.isArray(response)
      ? response
      : Object.entries<ResponseFragment>(response).map(([, v]) => {
          return {
            ...v,
          };
        });

    const data = normalizedResponse
      .filter((fragment) => !!fragment.data)
      .map((fragment) => ({
        type: fragment.type,
        data: {
          ...fragment.data['0'],
          coefficient: fragment.data.coefficient,
          total: fragment.data.total,
        },
      })) as Fragment[];

    return prepareData(data);
  }, []);

  const getData = useCallback(async () => {
    setLoading(true);
    setFragments([]);

    try {
      const response = await searchService.get('/component_fragments', {
        params: {
          filter: keyword,
          component_id,
          locale_id,
          price_type_id,
          page: pagination.page,
          per_page: pagination.per_page,
        },
      });

      const mapping = response.data;
      const fragmentsData = mapping.fragments[0];

      const newPagination = {
        last_page: fragmentsData.last_page,
      };

      const newSummary = {
        component: mapping.composition[0],
        inputs: mapping.summary.total_inputs,
        compositions: mapping.summary.total_compositions,
      };

      setSummary(newSummary);
      setFragments(normalizeResponse(fragmentsData.data));

      setServicePagination(newPagination);
    } catch (err) {
      toast({
        description: 'Houve um erro ao carregar o mapeamento da composição.',
        status: 'error',
      });

      setSummary({} as Summary);
      setFragments([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [
    keyword,
    pagination,
    component_id,
    locale_id,
    price_type_id,
    normalizeResponse,
  ]);

  useEffect(() => {
    if (pagination.page > servicePagination?.last_page) {
      setPagination((oldPagination) => {
        if (oldPagination.page > 1) {
          return {
            ...oldPagination,
            page: 1,
          };
        }

        return oldPagination;
      });
    }
  }, [pagination.page, servicePagination, setPagination]);

  useEffect(() => {
    getData();
  }, [getData]);

  const isInput = (data: Fragment): data is SinapiInputFragment => {
    return data.type === 'input';
  };

  const getType = (row: Row): 'composition' | 'input' => {
    return row.original.type;
  };

  const getIcon = (typ: 'composition' | 'input'): MdiReactIconComponentType => {
    if (typ === 'composition') {
      return GroupIcon;
    }

    return InfoIcon;
  };

  type InputIcon = MdiReactIconComponentType | undefined;
  const getInputIcon = useCallback((i: SinapiInputFragment): InputIcon => {
    const value = i.data.input_id.classification_id.key;

    if (!value) {
      return undefined;
    }

    if (value === 'M') {
      return BricksIcon;
    }

    return ShovelIcon;
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: () => 'Tipo',
        id: 'expander',
        Cell: ({ row }: { row: Row }) => {
          return (
            <HStack>
              <Icon w={4} h={4} as={getIcon(getType(row))} />

              {getType(row) === 'input' &&
                isInput(row.original) &&
                row.original.data.input_id.classification_id && (
                  <Icon w={4} h={4} as={getInputIcon(row.original)} />
                )}

              {getType(row) === 'composition' &&
                (row.isExpanded ? (
                  <Icon w={4} h={4} as={DownArrowIcon} />
                ) : (
                  <Icon w={4} h={4} as={RightArrowIcon} />
                ))}
            </HStack>
          );
        },
      },
      {
        Header: 'Código',
        accessor: 'data.code',
        width: '10%',
        Cell: ({ row }: { row: Row }) => {
          return isInput(row.original)
            ? row.original.data.input_id.code
            : row.original.data.composition_id.code;
        },
      },
      {
        Header: 'Descrição',
        accessor: 'data.description',
        width: '70%',
        Cell: ({ row }: { row: Row }) => {
          return (
            <Text
              maxWidth={{ base: '100%', md: '768px' }}
              whiteSpace="pre-wrap"
            >
              {isInput(row.original)
                ? row.original.data.input_id.description
                : row.original.data.composition_id.description}
            </Text>
          );
        },
      },
      {
        Header: 'Unidade',
        accessor: 'data.unit_measure_id',
        width: '10%',
        Cell: ({ row }: { row: Row }) => {
          return isInput(row.original)
            ? row.original.data.input_id.unit_measure_id.description
            : row.original.data.composition_id.unit_measure_id.description;
        },
      },
      {
        Header: 'Coeficiente',
        accessor: 'data.coefficient',
        isNumeric: true,
        width: '10%',
        Cell: ({ row }: { row: Row }) => {
          return row.original.data.coefficient;
        },
      },
      {
        Header: 'Valor unitário',
        accessor: 'data.unit_value',
        isNumeric: true,
        width: '10%',
        Cell: ({ row }: { row: Row }) => {
          const value = row.original.data.unit_value;

          return Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value || 0);
        },
      },
      {
        Header: 'Total',
        accessor: 'data.total',
        isNumeric: true,
        width: '15%',
        Cell: ({ row }: { row: Row }) => {
          const value = row.original.data.total;

          return Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value || 0);
        },
      },
    ],
    [getInputIcon],
  );

  const getRowId = useCallback((row) => {
    return row.id;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      // @ts-expect-error types
      columns,
      data: fragments,
      getRowId,
    },
    useExpanded,
  );

  const renderRowSubComponent = useCallback(
    ({ nextComponentId }) => (
      <FragmentsTable
        isSubRow
        level={level + 1}
        component_id={nextComponentId}
        locale_id={locale_id}
        price_type_id={price_type_id}
      />
    ),
    [level, locale_id, price_type_id],
  );

  return (
    <>
      {isSubRow && (
        <Flex
          color="white"
          width="100%"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          {summary.component && (
            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Classe</Text>

              <Text as="span" fontSize="smaller" fontStyle="italic">
                {summary.component &&
                  summary.component.type_id.classification_id.description}
              </Text>
            </Box>
          )}

          {summary.component && (
            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Tipo</Text>
              <Text fontSize="smaller" fontStyle="italic">
                {summary.component && summary.component.type_id.description}
              </Text>
            </Box>
          )}

          {summary.compositions > 0 && (
            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Composições</Text>
              <Text fontSize="smaller" fontStyle="italic">
                {summary.compositions}
              </Text>
            </Box>
          )}

          {summary.inputs > 0 && (
            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Insumos</Text>
              <Text fontSize="smaller" fontStyle="italic">
                {summary.inputs}
              </Text>
            </Box>
          )}
        </Flex>
      )}

      <SimpleGrid columns={{ base: 1, md: 2 }} mt={7} mb={4}>
        <HStack mb={3}>
          <Text fontSize="smaller" color={isSubRow ? 'white' : 'inherit'}>
            Por página:
          </Text>
          <Select
            width="auto"
            defaultValue={pagination.per_page}
            onChange={(e) => {
              setPagination({
                ...pagination,
                per_page: Number(e.target.value),
              });
            }}
          >
            {[5, 10, 20, 50, 100].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </HStack>

        <Flex
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          mb={3}
          gridGap={{ base: 0, md: 3 }}
        >
          <Input
            type="text"
            placeholder="Pesquisar"
            defaultValue={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            mb={{ base: 3, md: 0 }}
          />
        </Flex>
      </SimpleGrid>

      <TableContainer width="100%">
        <Table {...getTableProps()} borderBottom="none" variant="simple">
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps()}
                    width={column.width}
                    // @ts-expect-error isNumeric does not exists on lib type
                    isNumeric={column.isNumeric}
                  >
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {!loading &&
              rows.length > 0 &&
              rows.map((row) => {
                prepareRow(row);

                return (
                  <FragmentTableRow
                    row={row as unknown as Row}
                    visibleColumns={visibleColumns}
                    level={level}
                    renderRowSubComponent={renderRowSubComponent}
                    {...row.getRowProps()}
                  />
                );
              })}

            {loading && (
              <Tr>
                <Td
                  colSpan={visibleColumns.length}
                  borderBottom="none"
                  background="gray.100"
                >
                  <Loader />
                </Td>
              </Tr>
            )}

            {!loading && fragments.length === 0 && (
              <Tr>
                <Td
                  colSpan={visibleColumns.length}
                  borderBottom="none"
                  background="gray.100"
                  className="text-center"
                >
                  Nenhum resultado encontrado.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationWrapper
        useAlternativeColors={isSubRow}
        lastPage={servicePagination.last_page}
        onPaginate={(selectedPage) => {
          setPagination({ ...pagination, page: selectedPage });
        }}
      />
    </>
  );
};

export default FragmentsTable;
