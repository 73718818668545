import ModalBaseSelect from 'components/Modal/BaseSelect';
import ModalBudgetCompositionQuantity from 'components/Modal/BudgetCompositionQuantity';
import ModalBudgetCompositionSelect from 'components/Modal/BudgetCompositionSelect';
import ModalBudgetCustomCompositionAdd from 'components/Modal/BudgetCustomCompositionAdd';
import ModalBudgetHistory from 'components/Modal/BudgetHistory';
import ModalBuilderLookup from 'components/Modal/BuilderLookup';
import ModalCategoryUserAdd from 'components/Modal/CategoryUserAdd';
import ModalCompositionLookup from 'components/Modal/CompositionLookup';
import ModalCompositionPricesLookup from 'components/Modal/CompositionPriceLookup';
import CustomCompositionHistory from 'components/Modal/CustomCompositionHistory';
import ModalGroupLookup from 'components/Modal/GroupLookup';
import ModalInputPricesLookup from 'components/Modal/InputPriceLookup';
import ModalLegalText from 'components/Modal/LegalText';
import ModalManageCompositionFlow from 'components/Modal/ManageCompositionFlow';
import ModalManageFlow from 'components/Modal/ManageFlow';
import ModalUserCategoryAdd from 'components/Modal/UserCategoryAdd';
import ModalUserInvite from 'components/Modal/UserInvite';
import ModalManagerInvite from 'components/Modal/UserInviteManager';
import ModalUserOrganizationLookup from 'components/Modal/UserOrganizationLookup';

export default {
  baseSelect: ModalBaseSelect,
  compositionLookup: ModalCompositionLookup,
  compositionPriceLookup: ModalCompositionPricesLookup,
  inputPriceLookup: ModalInputPricesLookup,
  userInvite: ModalUserInvite,
  managerInvite: ModalManagerInvite,
  budgetCompositionSelect: ModalBudgetCompositionSelect,
  budgetCompositionQuantity: ModalBudgetCompositionQuantity,
  budgetCustomCompositionAdd: ModalBudgetCustomCompositionAdd,
  flowChange: ModalManageFlow,
  compositionFlowChange: ModalManageCompositionFlow,
  budgetHistory: ModalBudgetHistory,
  customCompositionHistory: CustomCompositionHistory,
  groupLookup: ModalGroupLookup,
  legalText: ModalLegalText,
  userOrganizationLookup: ModalUserOrganizationLookup,
  builderLookup: ModalBuilderLookup,
  userCategoryAdd: ModalUserCategoryAdd,
  categoryUserAdd: ModalCategoryUserAdd,
};
