import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { api } from 'services/api';
import { formatDate } from 'shared/date';
import { isSuperOrEcustosAdmin } from 'shared/roles';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
  HStack,
  Flex,
  Input,
  Select,
  Button,
  Icon,
  Text,
} from '@chakra-ui/react';

import AppTable from 'components/AppTable';
import { ModalRootProps } from 'components/Modal/Root';
import PaginationWrapper from 'components/Pagination';

import AuthContext from 'contexts/AuthContext';

import { Organization } from 'hooks/useAuth';
import useDebouncedState from 'hooks/useDebouncedState';
import useThrottledState from 'hooks/useThrottledState';

import { Pagination, ServicePagination } from 'types/pagination';

const ModalUserOrganizationLookup: React.FC<ModalRootProps> = ({
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const { user, jwtManagement, bradescoId } = useContext(AuthContext);

  const [data, setData] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useDebouncedState<string>('');
  const [pagination, setPagination] = useThrottledState<Pagination>(
    (() => {
      return {
        per_page: 10,
        page: 1,
      };
    })(),
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    const user_id = isSuperOrEcustosAdmin(user) ? undefined : user.id;

    try {
      const response = await api.get('/organization/user', {
        params: {
          'filter[keyword]': keyword,
          'filter[status]': 'active',
          page: pagination.page,
          per_page: pagination.per_page,
          'filter[user_id]': user_id,
          'filter[organization_id]': bradescoId,
        },
      });

      const userOrganizations = response.data;

      const newPagination = {
        last_page: userOrganizations.meta.last_page,
      };

      setData(userOrganizations.data);
      setServicePagination(newPagination);
    } catch (err) {
      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [keyword, pagination, user, bradescoId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getOrganizationName = ({ organization }: Organization): string => {
    if (organization.document_type === 'cnpj') {
      if (!organization.fantasy_name) {
        return organization.corporate_name;
      }

      return `${organization.corporate_name} - ${organization.fantasy_name}`;
    }

    return organization.name;
  };

  const getUserName = ({ user: u }: Organization): string => {
    if (u.document_type === 'cnpj') {
      if (!u.fantasy_name) {
        return u.corporate_name;
      }

      return `${u.corporate_name} - ${u.fantasy_name}`;
    }

    return u.name;
  };

  const handleConfirm = (organization: Organization): void => {
    if (onConfirm) onConfirm(organization);
    handleClose();
  };

  useEffect(() => {
    if (pagination.page > servicePagination?.last_page) {
      setPagination((oldPagination) => {
        if (oldPagination.page > 1) {
          return {
            ...oldPagination,
            page: 1,
          };
        }

        return oldPagination;
      });
    }
  }, [pagination.page, servicePagination, setPagination]);

  const canCloseModal = useMemo(() => {
    if (!user.managed_organization) {
      return false;
    }

    if (!jwtManagement) {
      return false;
    }

    return true;
  }, [user.managed_organization, jwtManagement]);

  return (
    <Modal
      {...restProps}
      size="6xl"
      scrollBehavior="inside"
      closeOnEsc={canCloseModal}
      closeOnOverlayClick={canCloseModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Selecionar Organização</ModalHeader>

        {canCloseModal && <ModalCloseButton />}

        <ModalBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} mt={6}>
            <HStack mb={3}>
              <Text fontSize="smaller">Por página:</Text>
              <Select
                width="auto"
                defaultValue={pagination.per_page}
                onChange={(e) => {
                  setPagination({
                    ...pagination,
                    per_page: Number(e.target.value),
                  });
                }}
              >
                {[5, 10, 20, 50, 100].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </HStack>

            <Flex
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              mb={3}
              gridGap={{ base: 0, md: 3 }}
            >
              <Input
                type="text"
                placeholder="Pesquisar"
                defaultValue={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                mb={{ base: 3, md: 0 }}
              />
            </Flex>
          </SimpleGrid>

          <AppTable
            cols={[
              { field: 'name', description: 'Usuário', sortable: false },
              {
                field: 'organization',
                description: 'Organização',
                sortable: false,
              },
              {
                field: 'email_verified_at',
                description: 'Ativado em',
                sortable: false,
              },
              { field: 'actions', description: 'Ações', sortable: false },
            ]}
            mapping={(item) => ({
              id: item.id,
              name: (
                <>
                  <Text mb={1} fontWeight="bold">
                    {getUserName(item)}
                  </Text>
                  <Text fontSize="smaller">{item.user.email}</Text>
                </>
              ),
              organization: <Text>{getOrganizationName(item)}</Text>,
              email_verified_at:
                item.user.email_verified_at &&
                formatDate(item.user.email_verified_at),
              actions: (
                <Button
                  colorScheme="green"
                  leftIcon={<Icon as={CheckIcon} />}
                  onClick={() => handleConfirm(item)}
                  disabled={item.id === user.managed_organization?.id}
                >
                  Selecionar
                </Button>
              ),
            })}
            data={data}
            loading={loading}
            onUpdateSort={() => {
              //
            }}
          />

          <PaginationWrapper
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalUserOrganizationLookup;
