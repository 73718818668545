import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import * as components from './components';
import config from './config';

const styles = {
  global: {
    ':root': {
      fontSize: '15px',
    },
    'html, body': {
      background: 'gray.50',
    },
  },
};

const customTheme = extendTheme({
  config,
  colors,
  styles,
  fonts: {
    body: "'Bradesco Sans', system-ui, sans-serif",
    heading: "'Bradesco Sans', system-ui, sans-serif",
    mono: 'Menlo, monospace',
  },
  transition: {
    transitionTimingFunction: 'ease-in-out',
    transitionDuration: '200ms',
  },
  components: {
    ...components,
  },
});

export default customTheme;
